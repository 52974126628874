<template>
  <!--begin::Container-->
  <div class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="250px"
        lazy-src="/media/biz-header.jpg"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white"></div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="container">
        <div class="text-lg-right">
          <v-btn elevation="9" rounded color="grey" @click="$router.go(-1)">
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            Back
          </v-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="container">
            <div class="row ">
              <div class="col-lg-4">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header">
                    <div class="card-title">
                      <h3 class="card-label">Billing Info</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer</h5> -->

                    <p class="mb-2">
                      {{ billto.company_name }}<br />
                      {{ billto.address_line1 }}<br />
                      {{ billto.address_line2 }}<br />
                      {{ billto.city }} {{ billto.state }} {{ billto.zip_code }}
                    </p>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2">Phone:</span>
                      (123) 456-7890
                    </p>
                  </div>
                </v-card>
              </div>
              <!-- end col -->
              <div class="col-lg-4">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header">
                    <div class="card-title">
                      <h3 class="card-label">Shipping Info</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer Recieving</h5> -->

                    <p class="mb-2">
                      {{ shipto.company_name }}<br />
                      {{ shipto.address_line1 }}<br />
                      {{ shipto.address_line2 }}<br />
                      {{ shipto.city }} {{ shipto.state }} {{ shipto.zip_code }}
                    </p>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2">Phone:</span>
                      (123) 456-7890
                    </p>
                  </div>
                </v-card>
              </div>
              <!-- end col -->

              <div class="col-lg-4">
                <v-card flat>
                  <ul class="list-unstyled mb-0">
                    <li>
                      <p v-if="vendor_order_invoices.length > 0" class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Master Order #:</span
                        >
                        {{ vendor_order_invoices[0].main_order_id }}
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Supplier Order #:</span
                        >
                        {{ $route.params.id }}
                      </p>
                      <p v-if="vendor_order_invoices.length > 0" class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Purchase Order #:</span
                        >
                        {{ vendor_order_invoices[0].po_number }}
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Payment Type:</span
                        >
                        NET TERMS
                      </p>
                    </li>
                  </ul>
                </v-card>
              </div>
              <!-- end col -->
            </div>

            <div class="row  ">
              <div class="col-lg-12">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header card-header-tabs-line">
                    <div class="card-title">
                      <h3 class="card-label">
                        Invoices for order #{{ $route.params.id }}
                      </h3>
                    </div>
                    <div class="card-toolbar"></div>
                  </div>

                  <div class="card-body">
                    <!--begin: Datatable-->
                    <v-data-table
                      :headers="headers"
                      :items="vendor_order_invoices"
                      sort-by="name"
                      class="elevation-1"
                    >
                      <template #top>
                        <v-toolbar flat>
                          <v-toolbar-title>Invoices</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </template>
                      <template #item="{ item }">
                        <tr>
                          <td>
                            {{ item.id }}
                          </td>
                          <td>
                            {{ item.order_id }}
                          </td>
                          <td>
                            {{ item.created_at | date_format }}
                          </td>
                          <td>
                            {{ item.supplier_name }}
                          </td>
                          <!-- <td>
                              {{item.user_name}}
                            </td> -->
                          <!-- <td>
                              <span v-if="item.status != null"> {{item.status}} </span>
                              <span v-else> Pending </span>
                            </td> -->
                          <td>
                            <v-icon
                              small
                              class="mr-2"
                              @click="
                                $router.push('/store/invoice-detail/' + item.id)
                              "
                            >
                              mdi-eye
                            </v-icon>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <!--end: Datatable-->
                  </div>
                </v-card>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  data: () => ({
    headers: [
      { text: "Invoice ID" },
      { text: "Order ID" },
      { text: "Date" },
      { text: "Supplier Name" },
      // { text: 'User' },
      { text: "Actions", sortable: false },
    ],
    baseurl: process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",
    dialog: false,
    orderTab: "Information",
    selected: 2,
    model: "tab-2",
    orders: [],
    main_order_date: "",
    billto: [],
    shipto: [],
  }),
  apollo: {
    // vendor_order_invoices: {
    //   client: "ecomClient",
    //   query: gql`
    //     query OrderInvoices($vendor_order_id: Int) {
    //       vendor_order_invoices(vendor_order_id: $vendor_order_id) {
    //         id
    //         order_id
    //         created_at
    //         status
    //         total
    //         supplier_name
    //         main_order_id
    //         seller_name
    //         seller_user_name
    //         po_number
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       vendor_order_id: parseInt(this.$route.params.id),
    //     };
    //   },
    // },
  },

  created() {
    this.getVendorOrderInvoices();
  },
  methods: {
    subTotal(items) {
      let sum = 0;
      if (items.length > 0) {
        for (let i in items) {
          sum += items[i].qty_to_return * items[i].price;
        }
      }
      return sum;
    },
    isInt(n) {
      return n % 1 === 0;
    },
    async getVendorOrderInvoices() {
      await this.$apollo.queries.vendor_order_invoices.refetch();
      if (this.vendor_order_invoices.length > 0) {
        // let ares = await this.$apollo.query({
        //   client: "ecomClient",
        //   query: gql`
        //     query OrderAddress($main_order_id: Int) {
        //       order_address: order_address(main_order_id: $main_order_id) {
        //         id
        //         main_order_id
        //         address_line1
        //         address_line2
        //         city
        //         state
        //         zip_code
        //         address_type
        //         company_name
        //         first_name
        //         last_name
        //       }
        //     }
        //   `,
        //   variables: {
        //     main_order_id: parseInt(
        //       this.vendor_order_invoices[0].main_order_id
        //     ),
        //   },
        // });
        // let address_arr = ares.data.order_address;
        // for (let i in address_arr) {
        //   if (address_arr[i].address_type == "billto") {
        //     this.billto = address_arr[i];
        //   } else {
        //     this.shipto = address_arr[i];
        //   }
        // }
      }
    },
  },
};
</script>
